<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <b-img
            :src="appLogoImage"
            alt="logo"
            class="img-fluid"
          />
        </b-link>
        <b-link class="brand-logo">
          <h2 class="text-primary ml-1 text-center">
            {{ appName }}
          </h2>
        </b-link>

        <b-card-title class="mb-1 text-center">
          Forgot Password? 🔒
        </b-card-title>
        <b-card-text class="mb-2 text-center">
          Enter your email and we'll send you instructions to reset your password
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Send reset link
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>

      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BImg,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import { required, email } from '@validations'
import authService from '@core/services/auth/authService'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BImg,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          authService.forgotPassword({
            email: this.userEmail,
          }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Password Recovery Email Sent',
                text: "Please check your email inbox for a message from us. We've sent you a link to recover your password. If you don't see it in your inbox, please check your spam folder as well.",
                icon: 'MailIcon',
                variant: 'success',
              },
            })
            this.$router.push({ path: '/login' })
          })
            .catch(error => {
              this.toastErrorHandler(error)
            })
          // this.$router.push({ name: 'login' })
        }
      })
    },
  },
}
</script>

  <style lang="scss">
  @import '~@core/scss/vue/pages/page-auth.scss';
  </style>
